import React, { useEffect, useRef, useState } from "react"
import { makeid, isClientRender } from "../../helper/common"
import { animateCSS } from "./animation"
import _debounce from "lodash/debounce"

const debounceClose = _debounce(
  cb => {
    cb()
  },
  1000,
  {
    leading: true,
    trailing: false,
  }
)

const Modal = ({ children, open, onClose = () => {} }) => {
  if (isClientRender()) {
    const { current: modalRef } = useRef({ modalId: makeid(5) })
    const { current: html } = useRef(document.getElementsByTagName("html")[0])
    const [modalElm, setModalElm] = useState(null)

    const handleClose = () => {
      debounceClose(() => {
        animateCSS(modalElm, ".modal-content", "fadeOutUp faster", () => {
          modalElm.querySelector(".modal-content").style.visibility = "hidden"
        })
        animateCSS(modalElm, ".modal-background", "fadeOut faster", () => {
          modalElm.classList.remove("is-active")
        })
        if (onClose) onClose()
      })
    }

    useEffect(() => {
      html.className = open
        ? `${html.className} is-clipped`.trim()
        : html.className.replace("is-clipped", "").trim()
      const modalElm = document.getElementById(modalRef.modalId)
      setModalElm(modalElm)
      if (open) {
        modalElm.classList.add("is-active")
        modalElm.querySelector(".modal-content").style.visibility = "visible"
        animateCSS(modalElm, ".modal-content", "fadeInDown faster")
        animateCSS(modalElm, ".modal-background", "fadeIn faster")
      }
      return () => {
        html.className = html.className.replace("is-clipped", "").trim()
      }
    }, [open])

    return (
      <>
        <div id={modalRef.modalId} className={`modal`}>
          <div className="modal-background" onClick={handleClose} />
          <div className={`modal-content`}>
            <div className={`box is-tablet has-margin-x-4`}>{children}</div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={handleClose} />
        </div>
      </>
    )
  }
  return <></>
}

export default Modal
