export const animateCSS = (element, selector, animationName, callback = () => {}) => {
  const node = element.querySelector(selector)
  if (node) {
    node.className = `${node.className} animated ${animationName}`.trim()

    function handleAnimationEnd() {
      node.className = node.className.replace(`animated ${animationName}`, "").trim()
      node.removeEventListener("animationend", handleAnimationEnd)

      if (typeof callback === "function") callback()
    }

    node.addEventListener("animationend", handleAnimationEnd)
  }
}
