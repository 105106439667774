import React, { useState } from "react"
import Layout from "../components/layout"
import Axios from "axios"
import Modal from "../components/utils/modal"
import SEO from "../components/seo"

console.log("API Url: ", process.env.GATSBY_API_URL)
const renderPermuteList = (permuteList, lead) => {
  try {
    return (
      <ul>
        {permuteList.map((num, i) => (
          <li key={i}>{`${lead}${num}`}</li>
        ))}
      </ul>
    )
  } catch (error) {
    return <div>Error: {error.message}</div>
  }
}
const renderList = (data, onItemClick) => {
  try {
    return data
      .filter(t => t.first)
      .map((t, i) => (
        <div key={i}>
          <div className="is-size-1">{`ผลรวม ${t.sum}`}</div>
          <ul>
            {t.possible.map((tt, j) => (
              <li key={j}>
                {tt} <a onClick={() => onItemClick(tt)}>สลับเลข</a>
              </li>
            ))}
          </ul>
        </div>
      ))
  } catch (error) {
    return <div>Error: {error.message}</div>
  }
}

const axiosConfig = {
  headers: {
    "x-api-key": "IQtzHjjDq15SuBJeTg2hZaWYjI1BMPSY5p46jVk9",
  },
}

const IndexPage = ({ ...props }) => {
  const [lead, setLead] = useState("")
  const [response, setResponse] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openPermute, setOpenPermute] = useState(false)
  const [permuteResult, setPermuteResult] = useState(null)
  return (
    <Layout>
      <SEO title="Home" keywords={[`ทะเบียนสวย`, `ทะเบียนดี`, `หาเลขทะเบียนสวย`, `หาเลขทะเบียนดี`, `เลขทะเบียนผลรวมดี`, `ผลรวมดี`, `ฟรีทะเบียนสวย`, `ฟรีเลขทะเบียนสวย`]} />
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="field">
            <label className="label title">หมวดที่ต้องการ</label>
            <div className={`control is-large ${loading ? "is-loading" : ""}`}>
              <input
                className="input is-large"
                type="text"
                defaultValue={lead}
                placeholder="ใส่หมวดนำหน้า เช่น 9กก"
                onChange={e => {
                  setLead(e.target.value)
                }}
              />
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-link"
                onClick={async e => {
                  if (lead) {
                    setLoading(true)
                    const response = await Axios.get(
                      `https://6jzr324dt7.execute-api.ap-southeast-1.amazonaws.com/default/createGoodTabian?lead=${lead}&len=4`,
                      axiosConfig
                    )
                    setLoading(false)
                    setResponse(response)
                  } else {
                    alert("กรุณากรอกหมวดป้ายทะเบียน")
                  }
                }}
              >
                สร้างเลขทะเบียนสวย
              </button>
            </div>
          </div>
          {response &&
            renderList(response.data, async tabian => {
              const numPermute = tabian.replace(lead, "")
              setLoading(true)
              const response = await Axios.get(
                `https://6jzr324dt7.execute-api.ap-southeast-1.amazonaws.com/default/permutation?text=${numPermute}`,
                axiosConfig
              )
              setLoading(false)
              setPermuteResult(response)
              setOpenPermute(true)
            })}
          <Modal open={openPermute} onClose={() => setOpenPermute(false)}>
            {permuteResult && renderPermuteList(Object.keys(permuteResult.data), lead)}
          </Modal>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
